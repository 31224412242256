html, body  /*, and all other map parent selectors*/ {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.map {
  height: calc(100vh - 55px);
  width: 100%;
  vertical-align: top;
}

.signup-map {
  height: calc(100vh - 280px);
  width: 100%;
  vertical-align: top;
  margin: 0 0 10px 0;
}

.card-tab .card-body .tab-content {
  height: 100%;
}


#list-fluxes {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

#list-synergies {
  max-height: calc(100vh - 250px);
  overflow-y: auto;
}

#signup-step3 {
  max-height: calc(100vh - 55px);
  overflow-y: auto;
}
